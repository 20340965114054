module.exports = {
  siteTitle: 'Relic Ventures', // <title>
  manifestName: 'Relic',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Relic',
  subHeading:
    "Asset Backed token that focuses on Real Estate, Energy, Equities, Cryptocurrency and Bonds. Sign up to learn more!",
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/relicventures',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/RelicVentures',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com/relic_ventures',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:contact@relicventures.com',
    },
  ],
};
